import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Container, Row, Col } from 'react-grid-system';
import { styled } from 'styled-components';

import pracaRiver from './assets/praca-river.svg';
import bregavaRiver from './assets/bregava-river.svg';
import krivajaRiver from './assets/krivaja-river.svg';
import biosticaRiver from './assets/biostica-river.svg';
import sanaRiver from './assets/sana-river.svg';

const Wrapper = styled.div`
  background: #effbff;
  padding-top: 3rem;
  padding-bottom: 3rem;
`;

const Heading = styled.h3`
  color: #034d82;
  text-align: center;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 0.5rem;
`;

const Paragraph = styled.p`
  color: #8ea9c1;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2rem; /* 200% */
  letter-spacing: 0.01875rem;
  margin-bottom: 2rem;
`;

const SpacedRow = styled(Row)`
  margin-top: 2rem;
`;

const Card = styled.div`
  border-radius: 0.5rem;
  background: #fff;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 1.5rem;
`;

// const Decorator = styled.div`
//   border-radius: 0.5rem;
//   background: linear-gradient(318deg, #3b82f6 0%, #86b4ff 100%);
//   width: 3.5rem;
//   height: 3.5rem;
//   svg {
//     width: 100%;
//   }
// `;

const Heading2 = styled.h2`
  color: #034d82;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 0.5rem;
`;

const CardParagraph = styled.p`
  color: #8ea9c1;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0.01875rem;
  margin-top: 0;
`;

const SpacedCol = styled(Col)`
  margin-top: 2rem;
  cursor: pointer;
`;

const CardImage = styled.img`
  max-width: 100%;
  height: 7rem;
`;

const StyledCol = styled(Col)`
  background: linear-gradient(318deg, #3b82f6 0%, #86b4ff 100%);
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  justify-content: center;
  display: flex;
`;

const rivers = [
  {
    id: 0,
    uniqueName: 'bregava',
    name: 'Bregava',
    translationLabel: 'Landing.Rivers.Text.Bregava',
    imgSrc: bregavaRiver
  },
  {
    id: 1,
    uniqueName: 'krivaja',
    name: 'Krivaja',
    translationLabel: 'Landing.Rivers.Text.Krivaja',
    imgSrc: krivajaRiver
  },
  {
    id: 2,
    uniqueName: 'biostica',
    name: 'Bioštica',
    translationLabel: 'Landing.Rivers.Text.Biostica',
    imgSrc: biosticaRiver
  },
  {
    id: 3,
    uniqueName: 'praca',
    name: 'Prača',
    translationLabel: 'Landing.Rivers.Text.Praca',
    imgSrc: pracaRiver
  },
  {
    id: 4,
    uniqueName: 'sana',
    name: 'Sana',
    translationLabel: 'Landing.Rivers.Text.Sana',
    imgSrc: sanaRiver
  },
  {
    id: 5,
    uniqueName: 'sanica',
    name: 'Sanica',
    translationLabel: 'Landing.Rivers.Text.Sanica',
    imgSrc: pracaRiver
  },
  {
    id: 6,
    uniqueName: 'neretva',
    name: 'Neretva',
    translationLabel: 'Landing.Rivers.Text.Neretva',
    imgSrc: pracaRiver
  },
  {
    id: 7,
    uniqueName: 'dabar',
    name: 'Dabar',
    translationLabel: 'Landing.Rivers.Text.Dabar',
    imgSrc: pracaRiver
  },
  {
    id: 8,
    uniqueName: 'vrbas',
    name: 'Vrbas',
    translationLabel: 'Landing.Rivers.Text.Vrbas',
    imgSrc: pracaRiver
  },
  {
    id: 9,
    uniqueName: 'una',
    name: 'Una',
    translationLabel: 'Landing.Rivers.Text.Una',
    imgSrc: pracaRiver
  },
  {
    id: 10,
    uniqueName: 'ugar',
    name: 'Ugar',
    translationLabel: 'Landing.Rivers.Text.Ugar',
    imgSrc: pracaRiver
  },
  {
    id: 11,
    uniqueName: 'pliva',
    name: 'Pliva',
    translationLabel: 'Landing.Rivers.Text.Pliva',
    imgSrc: pracaRiver
  },
  {
    id: 12,
    uniqueName: 'fojnica',
    name: 'Fojnica',
    translationLabel: 'Landing.Rivers.Text.Fojnica',
    imgSrc: pracaRiver
  },
  {
    id: 13,
    uniqueName: 'drina',
    name: 'Drina',
    translationLabel: 'Landing.Rivers.Text.Drina',
    imgSrc: pracaRiver
  },
  {
    id: 14,
    uniqueName: 'tara',
    name: 'Tara',
    translationLabel: 'Landing.Rivers.Text.Tara',
    imgSrc: pracaRiver
  },
  {
    id: 15,
    uniqueName: 'cehotina',
    name: 'Čehotina',
    translationLabel: 'Landing.Rivers.Text.Cehotina',
    imgSrc: pracaRiver
  }
];

const RiverList = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleOpenRiver = riverName => {
    navigate(`rivers/${riverName}`);
  };

  return (
    <Wrapper>
      <Container>
        <Row justify="center">
          <Col xs={6}>
            <Heading>{t('RiverList.Heading')}</Heading>
            <Paragraph>{t('RiverList.Paragraph')}</Paragraph>
          </Col>
        </Row>

        {/* List */}
        <SpacedRow id="river-section">
          {rivers.map(river => {
            return (
              <SpacedCol
                xs={12}
                sm={6}
                md={4}
                key={river.id}
                onClick={() => handleOpenRiver(river?.uniqueName)}
              >
                <Card>
                  <Row>
                    <StyledCol>
                      <CardImage src={river.imgSrc} />
                    </StyledCol>
                  </Row>
                  <Row>
                    <Col>
                      <Heading2>{river.name}</Heading2>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <CardParagraph>{t(river.translationLabel)}</CardParagraph>
                    </Col>
                  </Row>
                </Card>
              </SpacedCol>
            );
          })}
        </SpacedRow>
      </Container>
    </Wrapper>
  );
};

export default RiverList;
