import React from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'styled-components';
import { Col, Container, Row } from 'react-grid-system';

const Wrapper = styled.div`
  margin-top: 4rem;
  margin-bottom: 4rem;
`;

const StyledH3 = styled.h3`
  color: #034d82;
  font-size: 1rem;
  margin-bottom: 1.5rem;
  text-align: center;
  font-weight: 600;
`;

const SponsorSection = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Row justify="center">
        <Col xs={12} sm={5} md={5}>
          <Wrapper>
            <StyledH3>{t('SponsorSection.Heading')}</StyledH3>
          </Wrapper>
        </Col>
      </Row>
    </Container>
  );
};

export default SponsorSection;
